import { AvatarBadge } from '@/components/pages/ethical-oath/badge/page.content'
import useOathStatsQuery from '@/hooks/queries/useOathStatsQuery'
import { formatNumbers } from '@/lib/utils'

export const ThirdStep = () => {
  const { data: oathCounter } = useOathStatsQuery({})
  const userOathCounter = oathCounter?.users_accepted
    ? formatNumbers(oathCounter?.users_accepted)
    : 0

  const oathCounterLabel = `You and ${userOathCounter} people have signed the Oath.`
  return (
    <div className="flex flex-col items-center justify-center gap-12 bg-white p-[80px] lg:p-10 sm:p-5">
      <div className="flex flex-col items-center gap-5">
        <span>{oathCounterLabel}</span>
        <div className="flex flex-col items-center gap-2.5">
          <span className="text-h2 font-bold">Hurray!🎉 </span>
          <span className="text-h4 font-bold sm:text-center">
            Thank you for signing the oath
          </span>
        </div>
      </div>
      <div className="flex flex-row items-center gap-12 sm:flex-col">
        <AvatarBadge />
        <div className="flex flex-col gap-4">
          <span>
            Your conscious decision to choose ethical practices is vital for the
            future of technology.
          </span>
          <span>
            As a token of our appreciation, we are delighted to award you with
            this badge. Display it with pride and inspire others 💪
          </span>
        </div>
      </div>
    </div>
  )
}
