import { Avatar, AvatarGroup } from '@mui/material'
import JoinImage from '@public/assets/join.png'
import Image from 'next/image'
import { ArrowOutward, Button } from '@/lib/mui'

export const FirstStep = ({ handler }: { handler: () => void }) => (
  <div className="mt-6 flex flex-col items-center p-5">
    <Image
      src={JoinImage}
      alt="Person holding a flower in front of a robotic arm"
      width={400}
      height={200}
      className="w-full rounded-lg object-cover"
    />
    <div className="flex flex-col items-center px-20 sm:px-6">
      <h1 className="text-2xl mt-10 text-center font-bold">
        Join the movement toward a more ethical and sustainable future.
      </h1>
      <p className="mt-5 text-center text-gray-600">
        Sign the MDI Ethical Oath and show your commitment to creating a better
        world through technology. Let&apos;s create a brighter future together!
      </p>
      <Button
        color="primary"
        endIcon={<ArrowOutward />}
        onClick={handler}
        className="mt-6"
      >
        Read the Ethical Oath
      </Button>
      <AvatarGroup max={4} className="mt-8">
        {[...Array(6)].map((_, i) => (
          <Avatar
            alt="Remy Sharp"
            src={`https://loremflickr.com/320/240/girl?lock=${i}`}
            key={i}
          />
        ))}
      </AvatarGroup>
      <p className="mb-4 text-sm text-gray-500">
        30k Developers have signed the Oath
      </p>
    </div>
  </div>
)
