import { OathDialog } from '@/components/GlobalDialogs/EthicalOath/EthicalOath'
import { createContext, useContext, useState } from 'react'

interface DialogState {
  open: boolean
  /* add more standardized properties as needed */
  // title?: string
}

const globalDialogsContextInitState = {
  state: { ethicalOath: { open: false } as DialogState },
  loading: { ethicalOath: false },
  actions: {
    setEthicalOath: (_: DialogState) => {},
  },
} as const

const GlobalDialogsContext = createContext(globalDialogsContextInitState)

export const useGlobalDialogs = () => {
  if (!GlobalDialogsContext) {
    throw new Error(
      'useGlobalDialogs must be used within a GlobalDialogsProvider',
    )
  }
  return useContext(GlobalDialogsContext)
}

export const GlobalDialogsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [ethicalOath, setEthicalOath] = useState<DialogState>({ open: false })
  return (
    <GlobalDialogsContext.Provider
      value={{
        state: { ethicalOath },
        loading: { ethicalOath: false },
        actions: { setEthicalOath },
      }}
    >
      {children}
      <OathDialog />
    </GlobalDialogsContext.Provider>
  )
}
