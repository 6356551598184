import LoadingButton from '@mui/lab/LoadingButton'
import { ReactNode } from 'react'

export default function LoadingBtn({
  loading: isLoading,
  className,
  color,
  children,
  onClick,
  disabled = false,
  icon = <p></p>,
  size = 'medium',
  variant = 'contained',
  type = 'submit',
  loadingPosition = 'start',
  ...props
}: {
  loading: boolean
  icon?: ReactNode
  className?: string
  type?: 'button' | 'submit'
  size?: 'small' | 'medium' | 'large'
  loadingPosition?: 'start' | 'end' | 'center'
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  children: ReactNode
  variant?: 'contained' | 'text' | 'outlined'
  disabled?: boolean
  onClick?: (e: any) => Promise<void> | void
}) {
  return (
    <LoadingButton
      className={className}
      size={size}
      color={color}
      endIcon={isLoading ? undefined : icon}
      startIcon={isLoading ? icon : undefined}
      loading={isLoading}
      variant={variant}
      disabled={disabled}
      loadingPosition={loadingPosition}
      type={type}
      onClick={onClick}
      {...props}
    >
      {children}
    </LoadingButton>
  )
}
