'use client'
import { createContext } from "react";
import { FeatureFlagEnum } from "@/components/FeatureFlags/FeatureFlagEnum";

/**
 * Context used in the react provider for client components
 */
export const FeatureToggleContext = createContext({
  enabledFeatures: [] as FeatureFlagEnum[],
  enableFeature: (featureName: FeatureFlagEnum) => {},
  disableFeature: (featureName: FeatureFlagEnum) => {},
});