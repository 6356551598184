// to be used for client components
import config from '@/lib/config'
import { MdipInternal, MdipInternalFetchOptions } from '@/lib/mdip/core'
import { Path, PathMethod, RequestParams, ResponseType } from '@/lib/mdip/types'
// import { Session } from 'next-auth'

// const API_BASE_URL = config.NEXT_PUBLIC_API_URL
const API_BASE_URL = config.NEXT_PUBLIC_API_URL.endsWith('/')
  ? config.NEXT_PUBLIC_API_URL.slice(0, -1)
  : config.NEXT_PUBLIC_API_URL

// it make a call to /api/proxy
export const MdipClient = async <P extends Path, M extends PathMethod<P>>({
  url,
  method,
  payload,
  options,
  params,
  // session,
}: {
  url: P
  method: M
  payload?: any
  options?: MdipInternalFetchOptions
  params?: RequestParams<P, M> extends undefined ? [] : [RequestParams<P, M>]
  // session: Session | null
}): Promise<ResponseType<P, M> | ResponseType<P, M>[]> => {
  const req = await MdipInternal({
    // url: API_BASE_URL + url,
    url: '/api/proxy',
    method,
    payload,
    params,
    options: {
      ...options,
      headers: {
        ProxiedURL: API_BASE_URL + url,
        ...options?.headers,
      },
    },
    // sessionToken: session?.token,
  })
  if (!req.ok) {
    const error = await req.text()
    throw new Error(error)
  } else {
    // if (method.toLowerCase() === 'delete') {
    //   // Discuss with BE to return a valid response instead of No Content response
    //   req.json = async () => ({ message: 'deleted successfully', error: false })
    // }
    return req.json()
  }
}
