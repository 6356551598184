export enum AnalitycsEvents {
  firstSignIn = 'First Sign In',
  signIn = 'Sign In',
  discord = 'Discord',
  signEthicalOath = 'Sign Ethical Oath',
  firstSignEthicalOath = 'First Sign Ethical Oath',
  clickTrySampleData = 'Click Try Sample Data',
  createApiKey = 'Create API Key',
  clickLicence = 'Click Licence',
  clickSaveDatasetToCollection = 'Click Save Dataset To Collection',
  clickDownloadTrainedModel = 'Click Download Trained Model',
  trainingRunUploadStart = 'Training Run Upload start',
  trainingRunUploadEnd = 'Training Run Upload end',
  trainingRunSuccess = 'Training Run Upload finished successfully',
  trainingRunFailed = 'Training Run Upload failed',
}
