'use client'
import { useEffect, useState } from 'react'
import Hotjar from '@hotjar/browser'
import * as Amplitude from '@amplitude/analytics-browser'

export let analitycs: AnalitycsI = {
  track: (event: string, options?: { text: string }) => {
    return analitycs
  },
  identify: (key: string, value: string) => {
    return analitycs
  },
  setUserId: (userId: string) => {
    return analitycs
  },
  setOptOut: (flag: boolean) => {
    return analitycs
  },
}

interface AnalitycsI {
  track: (event: string, options?: { text: string }) => typeof analitycs
  identify: (key: string, value: string) => typeof analitycs
  setUserId: (userId: string) => typeof analitycs
  setOptOut: (flag: boolean) => typeof analitycs
}
interface AnalyticsInitI {
  hotjarId: number
  hotjarVersion: number
  amplitudeId: string
  isDev: boolean
}

const initExternalLibraries = async ({
  hotjarId,
  hotjarVersion,
  isDev,
  amplitudeId,
}: AnalyticsInitI) => {
  if (hotjarId) {
    Hotjar.init(hotjarId, hotjarVersion, {
      debug: isDev,
    })
  }
  Amplitude.init(amplitudeId, undefined, {
    logLevel: Amplitude.Types.LogLevel.Warn,
    defaultTracking: {
      sessions: true,
    },
  })
  const AmplitudeIdentifyEvent = new Amplitude.Identify()
  return { Hotjar, Amplitude, AmplitudeIdentifyEvent }
}

//https://www.docs.developers.amplitude.com/data/sdks/browser-2/#initialize-the-sdk
//https://help.hotjar.com/hc/en-us
const useAnalyticsInit = (config: AnalyticsInitI) => {
  const [doIHavePermission, setDoIHavePermission] = useState(false)
  const [analitycsStarted, setAnalitycsStarted] = useState(false)

  const handler = function (eventData: any) {
    const data = eventData.detail
    localStorage.setItem('cookie-consent', JSON.stringify(data))
    if (data.accepted.includes('analytics')) {
      setDoIHavePermission(true)
    } else {
      setDoIHavePermission(false)
    }
  }

  useEffect(() => {
    document.addEventListener('cookieyes_consent_update', handler)
    return () => document.addEventListener('cookieyes_consent_update', handler)
  }, [])

  useEffect(() => {
    const consentJson = localStorage.getItem('cookie-consent')
    try {
      if (consentJson) {
        const consent = JSON.parse(consentJson)
        if (consent && consent.accepted.includes('analytics')) {
          setDoIHavePermission(true)
        } else {
          setDoIHavePermission(false)
        }
      } else {
        setDoIHavePermission(false)
      }
    } catch {
      console.error("can't parse cookie consent form localStorage")
    }
  }, [])

  useEffect(() => {
    const initAmplitude = async () => {
      setAnalitycsStarted(true)
      const { Hotjar, Amplitude, AmplitudeIdentifyEvent } =
        await initExternalLibraries(config)

      analitycs = {
        track: (event, options) => {
          Amplitude.track(event, options)
          return analitycs
        },
        identify: (key: string, value: string) => {
          AmplitudeIdentifyEvent.set(key, value)
          Amplitude.identify(AmplitudeIdentifyEvent)
          return analitycs
        },
        setUserId: (userId: string) => {
          Amplitude.setUserId(userId)
          Hotjar.identify(userId, {})
          return analitycs
        },
        setOptOut: (flag: boolean) => {
          Amplitude.setOptOut(flag)
          // todo add Hotjar optout
          return analitycs
        },
      }
    }
    if (!analitycsStarted && doIHavePermission) {
      initAmplitude()
      analitycs.setOptOut(false)
    }
    if (analitycsStarted && doIHavePermission) {
      analitycs.setOptOut(true)
    }
  }, [doIHavePermission])
}

export default useAnalyticsInit
