'use client'
import { Organization } from '@/lib/mdip/types'
import { useState, createContext } from 'react'

export const LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED =
  'LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED'

const AppContext = createContext<{
  state: { organization: Organization | null }
  loading: { organization: boolean }
  actions: {
    setOrganization: (organization: Organization) => void
  }
}>({
  state: { organization: null },
  loading: { organization: false },
  actions: { setOrganization: () => {} },
})

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [organization, setOrganization] = useState<Organization | null>(null)
  const value = {
    state: { organization },
    loading: { organization: false },
    actions: {
      setOrganization: (organization: Organization) => {
        setOrganization(organization)
        localStorage.setItem(
          LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED,
          JSON.stringify(organization),
        )
      },
    },
  }
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export { AppContext, AppProvider }
