import { TinaMarkdown, TinaMarkdownContent } from 'tinacms/dist/rich-text'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export const MarkdownLocal = ({
  content,
  replaceData,
}: {
  content: string | TinaMarkdownContent
  replaceData?: any
}) => {
  if (typeof content === 'string' || content instanceof String) {
    if (replaceData) {
      for (const key in replaceData) {
        if (replaceData[key]) {
          content = content.replaceAll(`{{${key}}}`, replaceData[key])
        } else {
          content = content.replaceAll(`{{${key}}}`, '_+_')
          content = content.replaceAll(`**_+_**`, '')
        }
      }
    }
    return (
      <Markdown remarkPlugins={[remarkGfm]} className="markdown">
        {content as string}
      </Markdown>
    )
  } else {
    return (
      <span className="markdown">
        <TinaMarkdown content={content as TinaMarkdownContent} />
      </span>
    )
  }
}
