import { Dispatch, SetStateAction } from 'react'
import {
  IndoorOutdoorEnum,
  IndoorOutdoorEnumDescription,
} from 'sdk/openapitypescript/mdip-api'

interface Button {
  text: string
  href?: string | null
  externalHref?: string | null
}

export const CMSLink = (button: Button) => {
  return button.externalHref
    ? { href: button.externalHref as string, target: '_blank' }
    : { href: button.href as string }
}

export enum AuthErrors {
  incorrectCredentials = 'The email or password is invalid. Please try again.',
  userDisabled = 'Account has been disabled. Please contact support!',
  attemptsExceeded = 'Your account has been temporarily locked due to multiple failed login attempts. Press "Forgot Password" or contact support.',
  changePasswordRequired = 'changePassword',
  passwordExpired = 'Password expired and must be reset by an administrator',
  preAuthentication = "PreAuthentication failed with error 'sub'.",
  usernameExistsException = 'An account with the given email already exists.',
  userIsNotConfirmed = 'User is not confirmed.',
}
/**
 * @public
 */
export enum AuthMessages {
  success = 'SUCCESS',
  done = 'DONE',
}
export const AuthErrorMapper = (
  err?: string,
): AuthErrors | string | undefined => {
  let authErrorType
  switch (err) {
    case 'Temporary password has expired and must be reset by an administrator.':
      authErrorType = AuthErrors.passwordExpired
      break
    case 'Incorrect username or password.':
      authErrorType = AuthErrors.incorrectCredentials
      break
    case 'User is disabled.':
      authErrorType = AuthErrors.userDisabled
      break
    case 'Password attempts exceeded':
      authErrorType = AuthErrors.attemptsExceeded
      break
    case 'changePassword':
      authErrorType = AuthErrors.changePasswordRequired
    default:
      authErrorType = err
      break
  }
  return authErrorType
}

export const removeDuplicateObjects = <T>(array: T[], property: keyof T) => {
  const uniqueIds: any[] = []

  const unique = array.filter((element) => {
    const propertyValue = element[property]

    if (!uniqueIds.includes(propertyValue)) {
      uniqueIds.push(propertyValue)
      return true
    }

    return false
  })

  return unique
}

interface Item {
  isEnabled: boolean
}
export const getEnabled = <T extends Item>(items: T[]): T[] => {
  return items.filter((item) => item.isEnabled)
}

export const isMultipleOfSix = (
  number: number,
): { isMultiple: boolean; difference?: number } => {
  if (number % 2 === 0 && number % 3 === 0) {
    return { isMultiple: true }
  } else {
    // Calculate the difference from the nearest multiple of 6
    const nearestMultipleOfSix: number = Math.floor(number / 6) * 6
    const difference: number = 6 - Math.abs(number - nearestMultipleOfSix)
    return { isMultiple: false, difference }
  }
}

export const handleScrollToBottom =
  (dispatch: Dispatch<SetStateAction<boolean>>) => () => {
    const pageHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight
    const scrollPosition = window.scrollY || window.pageYOffset
    dispatch(pageHeight - (scrollPosition + windowHeight) >= 100)
  }

export const handlerCaptchaResize = (e: any) => {
  if (e.data.event !== 'init') {
    return
  }

  let turnstileIframe = document.getElementById(
    'cf-chl-widget-' + e.data.widgetId,
  )
  if (!turnstileIframe) {
    return
  }

  turnstileIframe.style.width = '100%'
  turnstileIframe.style.height = '65px'
  turnstileIframe.style.display = ''
  e.stopImmediatePropagation()
}

export const formatNumbers = (number: number): string => {
  if (number >= 1000 && number < 1000000) {
    // Thousands range
    return (number / 1000).toFixed(1) + 'K'
  } else if (number >= 1000000) {
    // Millions range
    return (number / 1000000).toFixed(1) + 'M'
  } else {
    // Number less than 1000
    return number.toString()
  }
}

const indoorOutdoorMap: Record<string, string> = {
  // Enforce mapping type
  Indoor: IndoorOutdoorEnumDescription.INDOOR,
  Ooutdoor: IndoorOutdoorEnumDescription.OUTDOOR,
  Both: IndoorOutdoorEnumDescription.BOTH,
}

export function getIndoorOutdoor(value: string): IndoorOutdoorEnum | string {
  return indoorOutdoorMap[value] || value
}

export const obscureEmail = (email: string | null | undefined) => {
  if (!email) return email
  const [name, domain] = email.split('@')
  const [domainName, tld] = domain.split('.')
  return `${name[0]}${new Array(name.length).join('*')}@${domainName[0]}${new Array(domainName.length).join('*')}.${tld}`
}

export const stringAvatar = (
  name: string | null = ' ',
  familyName: string | null = ' ',
) => {
  return {
    children: `${name ? name[0] : ''}${familyName ? familyName[0] : ''}`,
  }
}
