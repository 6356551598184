import assert from 'assert'

const config = {
  isDev: () => {
    return process.env.NODE_ENV === 'development'
  },
  NEXTAUTH_URL: process.env.NEXTAUTH_URL as string,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL as string,
  NEXT_PUBLIC_API_URL_NO_PROXY: process.env
    .NEXT_PUBLIC_API_URL_NO_PROXY as string,
  NODE_ENV: process.env.NODE_ENV as string,
  ANALYZE_BUNDLE: (process.env.ANALYZE_UNDLE as unknown as boolean) || false,
  NEXT_PUBLIC_AMPLITUDE_API_KEY: process.env
    .NEXT_PUBLIC_AMPLITUDE_API_KEY as string,
  NEXT_PUBLIC_CLOUDFLARE_SITE_KEY: process.env
    .NEXT_PUBLIC_CLOUDFLARE_SITE_KEY as string,
}

const validateConfig = () => {
  assert.ok(
    process.env.NEXT_PUBLIC_API_URL,
    'missing NEXT_PUBLIC_API_URL env var',
  )
  return config
}
export default (() => validateConfig())()

// export type Config = ReturnType<typeof validateConfig>
